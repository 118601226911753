const areas = [
    {
        id: '01',
        startPrice: 348,
        extra: 32
    },
    {
        id: '02',
        startPrice: 380,
        extra: 32
    },
    {
        id: '03',
        startPrice: 392,
        extra: 35
    },
    {
        id: '04',
        startPrice: 409,
        extra: 35
    },
    {
        id: '05',
        startPrice: 421,
        extra: 40
    },
    {
        id: '06',
        startPrice: 432,
        extra: 40
    },
    {
        id: '07',
        startPrice: 444,
        extra: 40
    },
    {
        id: '08',
        startPrice: 478,
        extra: 50
    },
    {
        id: '09',
        startPrice: 538,
        extra: 50
    },
    {
        id: '10',
        startPrice: 569,
        extra: 60
    },
    {
        id: '11',
        startPrice: 595,
        extra: 75
    },
    {
        id: '12',
        startPrice: 616,
        extra: 90
    },
    {
        id: '13',
        startPrice: 630,
        extra: 100
    },
    {
        id: '14',
        startPrice: 696,
        extra: 100
    },
    {
        id: '15',
        startPrice: 804,
        extra: 100
    },
    {
        id: '16',
        startPrice: 1725,
        extra: 250
    },
    {
        id: '17',
        startPrice: 2100,
        extra: 400
    }
];

export default areas;
