const supporters = [
    { id: 'wjiclkqe', name: 'Игорь Кузаев', date: '2024-0928', sum: 1500 },
    { id: 'ntljnnii', name: 'Андрей Юрьевич', date: '2024-0928', sum: 2000 },
    { id: 'fkarxdaq', name: 'Татьяна Николаевна', date: '2024-0928', sum: 772 },
    { id: 'ughajfai', name: 'Дима Ди', date: '2024-0928', sum: 3000 },
    { id: 'pmfqcgng', name: 'Ксения', date: '2024-0928', sum: 1500 },
    {
        id: 'eaplltsc',
        name: 'Ольга Александровна',
        date: '2024-0928',
        sum: 1000
    },
    { id: 'xrvclvss', name: 'Евгений С.', date: '2024-0928', sum: 1000 },
    {
        id: 'vtyntqac',
        name: 'Яна Станиславовна',
        date: '2024-0928',
        sum: 100
    },
    { id: 'juxoipqa', name: 'Анна Сергеевна', date: '2024-0928', sum: 300 },
    {
        id: 'kpfmwsam',
        name: 'Елена Б.',
        date: '2024-0928',
        sum: 1000
    },
    { id: 'rmbmgnqg', name: 'Татьяна Л.', date: '2024-0928', sum: 220 },
    { id: 'kghucpos', name: 'Надежда', date: '2024-0928', sum: 1500 },
    { id: 'gjnjshxi', name: 'Мария Журавлева', date: '2024-0928', sum: 1700 },
    { id: 'uqcgycth', name: 'Елена Николаевна', date: '2024-0928', sum: 700 },
    { id: 'yogpneeb', name: 'Александр ', date: '2024-0928', sum: 1500 },
    {
        id: 'fgoetkau',
        name: 'Александр Иванович',
        date: '2024-0928',
        sum: 1000
    },
    {
        id: 'xeabqpqa',
        name: 'Ирина Анатольевна',
        date: '2024-0928',
        sum: 500
    },
    {
        id: 'fnynjjgg',
        name: 'Екатерина Николаевна',
        date: '2024-0928',
        sum: 200
    },
    { id: 'fqpnjjua', name: 'Наталья Б.', date: '2024-0928', sum: 300 },
    { id: 'bxouyyby', name: 'Ни Оксана', date: '2024-0928', sum: 1500 },
    { id: 'unuickeb', name: 'Пирог Татьяна', date: '2024-0928', sum: 1500 },
    {
        id: 'lrdxkhmt',
        name: 'юmoney ***7534',
        date: '2024-0928',
        sum: 1940
    },
    { id: 'gwcxeylr', name: 'Николай', date: '2024-0928', sum: 3500 },
    { id: 'fyqlgubf', name: 'Михаил Михайлович', date: '2024-0928', sum: 5000 },
    { id: 'qnqrceud', name: 'Шлотгауэр Л.Л.', date: '2024-0928', sum: 1500 },
    { id: 'ejmqiwsr', name: 'Павел Иванов', date: '2024-0928', sum: 1500 },
    { id: 'lffvwhej', name: 'Сергей Балыбердин', date: '2024-0928', sum: 1500 },
    { id: 'hyweewlc', name: 'Артур Ш', date: '2024-0928', sum: 500 },
    { id: 'bcvdekfl', name: 'Белый Сергей ', date: '2024-0928', sum: 1500 },
    {
        id: 'soasxtwd',
        name: 'Marina Zebrika, paypal',
        date: '2024-0928',
        sum: 1820
    },
    { id: 'lkrvsxal', name: 'noname', date: '2024-0928', sum: 1000 },
    { id: 'wpfgdxjc', name: 'noname', date: '2024-0928', sum: 2412 },
    {
        id: 'vurcrlxc',
        name: 'Наталья Юрьевна Э.',
        date: '2024-0928',
        sum: 1000
    },
    {
        id: 'tmfchyul',
        name: 'Виталий',
        date: '2024-0928',
        sum: 1500
    },
    { id: 'mfbqkqt7', name: 'Ольга Сергеевна Д', date: '2024-0928', sum: 2000 },
    { id: 'rfbqgqtj', name: 'Кристина', date: '2024-0928', sum: 3000 },
    { id: 'tgbqkqta', name: 'Евгений С.', date: '2024-0928', sum: 5000 },
    { id: 'mfbqkq2u', name: 'Алексей В.', date: '2024-0928', sum: 500 },
    { id: 'yfbqkqt5', name: 'noname', date: '2024-0928', sum: 1500 },
    { id: 'yfehdqt5', name: 'Наталья Неудачина', date: '2024-0928', sum: 1500 },
    { id: 'qstthnax', name: 'Андрей Медведев', date: '2024-0928', sum: 3550 },
    {
        id: 'bbadsrie',
        name: 'Виктория Валерьевна',
        date: '2024-0928',
        sum: 1200
    },
    {
        id: 'bbxjmfie',
        name: 'Julia Alaverdashvili, paypal',
        date: '2024-0928',
        sum: 2415
    },
    { id: 'bcydgeie', name: 'noname, VTB', date: '2024-0928', sum: 2000 },
    {
        id: 'vyakvusy',
        name: 'Farber Igor, paypal',
        date: '2024-0928',
        sum: 5042
    },
    { id: 'aqfvpbjh', name: 'Ирина Курганова', date: '2024-0928', sum: 2000 },
    { id: 'yobruelp', name: 'Сергей Балыбердин', date: '2024-0928', sum: 1000 },
    { id: 'bvxgcpxj', name: 'Юлия', date: '2024-0928', sum: 1500 },
    { id: 'oekheris', name: 'Полина Николаевна', date: '2024-0928', sum: 1000 },
    { id: 'gofukrxn', name: 'Илья', date: '2024-0928', sum: 1500 },
    { id: 'bcemcdfk', name: 'Дмитрий', date: '2024-0928', sum: 2000 },
    { id: 'dhustgow', name: 'Питер', date: '2024-0928', sum: 1000 },
    { id: 'jjcqjgqj', name: 'noname', date: '2024-0928', sum: 186 },
    {
        id: 'vrvqkauw',
        name: 'Нина Михайловна',
        date: '2024-0928',
        sum: 1000
    },
    { id: 'forablsj', name: 'Алексей ', date: '2024-0928', sum: 1500 },
    { id: 'ojyesows', name: 'Александра К.', date: '2024-0928', sum: 329 },
    {
        id: 'rneacovc',
        name: 'Дмитрий К.',
        date: '2024-0928',
        sum: 1000
    },
    { id: 'igaasmie', name: 'Даниил', date: '2024-0928', sum: 2650 },
    { id: 'idfhlbxy', name: 'Юля Горячева', date: '2024-0928', sum: 2000 },
    { id: 'nlxmciai', name: 'noname', date: '2024-0928', sum: 5000 },
    { id: 'padrihgf', name: 'noname', date: '2024-0928', sum: 744 },
    { id: 'byjxiyit', name: 'noname', date: '2024-0928', sum: 186 },
    {
        id: 'donpusjt',
        name: 'noname',
        date: '2024-0928',
        sum: 186
    },
    { id: 'msqoqdoh', name: 'noname', date: '2024-0928', sum: 2000 },
    { id: 'yjdavahm', name: 'noname', date: '2024-0928', sum: 500 },
    { id: 'mvyqivws', name: 'Андрей', date: '2024-0928', sum: 1500 },
    { id: 'lpbycxxh', name: 'noname', date: '2024-0928', sum: 186 },
    { id: 'jdusytre', name: 'noname', date: '2024-0928', sum: 186 },
    { id: 'dflxqxam', name: 'noname', date: '2024-0928', sum: 186 },
    { id: 'chfuybhf', name: 'noname', date: '2024-0928', sum: 186 },
    { id: 'mxtuspoa', name: 'noname', date: '2024-0928', sum: 186 },
    { id: 'jhdyisua', name: 'noname', date: '2024-0928', sum: 186 },
    {
        id: 'sidutfre',
        name: 'Александр Иванович М.',
        date: '2024-0928',
        sum: 1500
    },
    { id: 'jfuyvrts', name: 'Ирина Мельникова', date: '2024-0928', sum: 1500 },
    { id: 'nxtrcodi', name: 'Юля Чижик', date: '2024-0928', sum: 500 },
    { id: 'kbtspbve', name: 'noname', date: '2024-0928', sum: 586 },
    { id: 'ycrrtfbi', name: 'noname', date: '2024-0928', sum: 2000 },
    { id: 'obpcyeay', name: 'noname', date: '2024-0928', sum: 2500 },
    { id: 'hfdobbyd', name: 'noname', date: '2024-0928', sum: 2500 },
    { id: 'jvtsonut', name: 'noname', date: '2024-0928', sum: 5000 },
    {
        id: 'fvvhduhc',
        name: 'Александр Юрьевич Ф.',
        date: '2024-0928',
        sum: 2001
    },
    { id: 'gfkdjygj', name: 'noname', date: '2024-0928', sum: 145 },
    { id: 'duvgrapb', name: 'noname', date: '2024-0928', sum: 5000 },
    { id: 'xhjadeop', name: 'Михаил Выходцев', date: '2024-0928', sum: 10000 },
    { id: 'ucdaqpjd', name: 'noname', date: '2024-0928', sum: 186 },
    { id: 'vyseaqob', name: 'Илья', date: '2024-0928', sum: 400 },
    {
        id: 'ncybhdsl',
        name: 'Александр Иванович М.',
        date: '2024-0928',
        sum: 1500
    },
    { id: 'gaycffgu', name: 'Урбахи', date: '2024-0928', sum: 10000 },
    { id: 'dkhjbwyq', name: 'Кислицына Ирина', date: '2024-0928', sum: 2050 },
    { id: 'cbsjdtfe', name: 'noname', date: '2024-0928', sum: 186 },
    { id: 'nctrsajv', name: 'noname', date: '2024-0928', sum: 186 },
    {
        id: 'duysteop',
        name: 'Татьяна Максимовна Т.',
        date: '2024-0928',
        sum: 2000
    },
    { id: 'kcfosawq', name: 'Елена', date: '2024-0928', sum: 1000 },
    { id: 'grsibovi', name: 'noname', date: '2024-0928', sum: 186 },
    { id: 'sghjyrej', name: 'newyear', date: '2024-0928', sum: 5700 },
    { id: 'jsyrtyew', name: 'newyear', date: '2024-0928', sum: 3500 },
    { id: 'bchdryhf', name: 'Николай А.', date: '2024-1228', sum: 10000 }, // date
    { id: 'czfarepk', name: 'newyear', date: '2024-0928', sum: 300 },
    {
        id: 'xasderto',
        name: 'Александр Казаков',
        date: '2024-0928',
        sum: 10000
    },
    { id: 'vglsawiy', name: 'newyear', date: '2024-0928', sum: 300 },
    { id: 'zxsdjfgr', name: 'noname', date: '2024-0928', sum: 186 },
    {
        id: 'sstjulmc',
        name: 'Афонин Михаил Юрьевич',
        date: '2024-0928',
        sum: 1500
    },
    { id: 'sdfsawhu', name: 'Дмитрий К.', date: '2024-1229', sum: 1000 }, // date
    { id: 'bnvjdiut', name: 'Наталья Н.', date: '2024-1231', sum: 1000 }, // date
    { id: 'kfhgrrew', name: 'noname', date: '2024-0928', sum: 186 },
    { id: 'xbfgoods', name: 'noname', date: '2024-0928', sum: 186 },
    { id: 'dhfgrjua', name: 'noname', date: '2024-0928', sum: 145 },
    { id: 'dwkbnsfa', name: 'newyear', date: '2024-0928', sum: 300 },
    { id: 'mmcyftre', name: 'noname', date: '2024-0928', sum: 186 },
    {
        id: 'cbnfhrys',
        name: 'Татьяна Коломенская, paypal',
        date: '2025-0109',
        sum: 3500
    }, // date
    { id: 'bchfytui', name: 'Василий Т.', date: '2025-0112', sum: 2000 }, // date
    { id: 'zbxgdtre', name: 'newyear', date: '2024-0928', sum: 186 },
    {
        id: 'ojhscqvj',
        name: 'Илья Владимирович М.',
        date: '2024-0928',
        sum: 50000
    },
    { id: 'jrwaocvz', name: 'noname', date: '2025-0120', sum: 186 }, // date
    { id: 'jkdfuyep', name: 'noname', date: '2025-0123', sum: 145 }, // date
    { id: 'bcgjtyso', name: 'noname', date: '2025-0127', sum: 186 }, // date
    { id: 'bngjdswa', name: 'noname', date: '2025-0127', sum: 1550 }, // date
    { id: 'ncttsvap', name: 'endline', date: '0000-0000', sum: 0 }
];

supporters.reverse();

export const capital = supporters.reduce((acc, item) => acc + item.sum, 0);

export default supporters;
